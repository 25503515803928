import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AdminFileUploadService} from './admin-file-upload.service';
import {FileTypeEnum} from './FileTypeEnum';
import {FileInfo} from '../../app-item/FileInfo';

@Component({
    selector: 'app-admin-file-upload',
    templateUrl: './admin-file-upload.component.html',
    styleUrls: ['./admin-file-upload.component.scss'],
    standalone: false
})
export class AdminFileUploadComponent {
  @Input() storeName: string;
  @Input() fileTypeEnum: FileTypeEnum;
  @Output() receiveFileNameUpdate = new EventEmitter();

  selectedFiles: FileList;
  // currentFileUpload: FileInfo;
  // percentage: number;

  constructor(private uploadService: AdminFileUploadService) {
  }

  selectFiles(event): void {
    this.selectedFiles = event.target.files;

    if (this.selectedFiles && this.selectedFiles.length) {
      this.receiveFileNameUpdate.emit(this.selectedFiles);
    }
  }

  // uploadFile(event): void {
  //   this.selectedFiles = event.target.files;
  //   const file = this.selectedFiles.item(0);
  //   // this.selectedFiles = undefined;
  //
  //   this.currentFileUpload = new FileInfo(file);
  //   this.uploadService.uploadFile(this.currentFileUpload, this.storeName, this.fileTypeEnum)
  //     .subscribe({
  //       next: fileUpload => {
  //         this.receiveFileNameUpdate.emit(fileUpload);
  //       },
  //       error: error => {
  //         console.log(error);
  //       }
  //     });
  // }



}
